<template>
	<div class="eventWraper" v-if="selarticleList.length > 0">
		<div class="rsTitle rsTitleBg flex align_items_c justify_content_sb">
			<div class="rsTitleLeft flex align_items_c justify_content_sb">
				<div>精选快讯</div>
				<div
					class="flex align_items_c pointer rsTitleLeft_more"
					@click="$router.push({ path: '/home_kuaixun' })"
				>
					<div class="rsTitleLeft_point"></div>
					<div class="rsTitleLeft_point"></div>
					<div class="rsTitleLeft_point"></div>
				</div>
			</div>
		</div>
		<div class="MainRight_2 MainRight_3 steps homesteps eventWraper_content">
			<el-steps
				direction="vertical"
				:active="1"
				style="height: 100%"
				class="homesteps_el"
			>
				<el-step v-for="(item, index) in selarticleList" :key="index">
					<template slot="description">
						<div
							class="stepsTime pointer hovercolor"
							@click="
								$router.push({
									path: '/kuaixun_wz',
									query: { id: item.id },
								})
							"
						>
							{{
								localLanguage == 2
									? item.title
										? item.title
										: item.title
									: item.title
							}}
						</div>
						<div class="flex justify_content_sb align_items_c">
							<div class="flex align_items_c btnitem fx">
								<div v-time="item.release_time"></div>
							</div>
						</div>
						<br />
					</template>
				</el-step>
			</el-steps>
		</div>
	</div>
</template>

<script>
import clipboard from "clipboard";
import { eventBus } from "../../components/pc/leftTab";
export default {
	props: ["selarticleList"],
	data() {
		return {
			currentShow: 0,
			localLanguage: 1,
		};
	},
	mounted() {
		eventBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "简体中文") {
				this.localLanguage = 0;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 1;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 1;
		}
	},
	methods: {
		changePart(ind) {
			this.currentShow = ind;
		},
	},
};
</script>

<style scoped>
.btn {
	width: 40px;
	height: 25px;
	margin-left: 12px;
	line-height: 25px;
	text-align: center;
	background: #fff;
	border-radius: 13px;
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #a0a0a9;
}
.activeBtn {
	color: #0056ff;
}

.homesteps_el {
	margin-top: 30px;
}
.homesteps .el-step__main {
	height: auto;
	margin-left: 10px;
}
.homesteps .stepsVal {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	margin-top: 5px;

	line-height: 21px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;

	font-size: 14px;
	font-weight: normal;

	/* 黑色主色（字段一级） */
	color: #333333;
}

.btnitem {
	margin-top: 7px;
}

.btnitem img {
	width: 14px;
	margin-right: 3px;
}
.fx div {
	/* font-size: 12px;
	font-family:  "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #a0a0a8; */

	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 13px;
	font-weight: normal;
	line-height: 13px;
	letter-spacing: 0em;

	color: #b2b2b2;
}
.MainRight_3 {
	padding-top: 0px;
	/* height: 600px; */
}
</style>

<style>
.eventWraper .el-step.is-vertical .el-step__line {
	top: 0px !important;
	/* margin-top: 10px; */
}
.eventWraper .el-step__description {
	margin-top: 0;
	padding-right: 0% !important;
}

.eventWraper .el-step__icon {
	top: -13px;
}

.eventWraper .el-step__main {
	padding-top: 0px;
	margin-top: -8px;
}
</style>
