<template>
	<div>
		<!-- 顶部导航   -->
		<div class="navBox" ref="navBox">
			<div
				class="navItem"
				ref="navItem"
				:class="{ activeItem: activeIndex === index }"
				v-for="(item, index) in categoryLists"
				:key="index + '1'"
				@click="changePart(index, $event)"
			>
				{{
					localLanguage == 2
						? item.name_en
							? item.name_en
							: item.name
						: item.name
				}}
				<img
					v-if="activeIndex === index"
					src="https://zy.metaera.media/assets/images/home/activeTag.png"
				/>
			</div>
		</div>

		<swiper :options="options" ref="mySwiper">
			<swiperSlide
				v-for="(item, index) in categoryLists"
				:key="index + '2'"
				v-show="activeIndex == index"
			>
				<template v-if="index == 0">
					<TopSwiper :bnnerLists="bnnerLists"></TopSwiper>
					<NewsPart></NewsPart>
					<EditorCommend :zbtjList="zbtjList"></EditorCommend>
					<div v-for="(hotItem, hotIndex) in hotLists" :key="hotIndex + '3'">
						<PerNews :item="hotItem" v-if="hotIndex < 5"></PerNews>
					</div>
					<PopColumn :popColumnLists="popColumnLists"></PopColumn>
				</template>
				<!-- <template v-else>
					<PerNews :item="item"></PerNews>
				</template> -->

				<div
					v-for="(articleItem, articleIndex) in articleList"
					:key="articleItem.id"
				>
					<PerNews :item="articleItem"></PerNews>
				</div>
			</swiperSlide>
		</swiper>
	</div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import TopSwiper from "../topSwiper";
import NewsPart from "../newsPart";
import EditorCommend from "../editorCommend";
import PerNews from "./perNews";
import PopColumn from "./popColumn";
import { getArticleList } from "@/api/home";
import { eventBus } from "../../../components/pc/leftTab";
export default {
	props: [
		"categoryLists",
		"bnnerLists",
		"zbtjList",
		"popColumnLists",
		"popColumnLists",
		"hotLists",
	],
	components: {
		TopSwiper,
		NewsPart,
		EditorCommend,
		PerNews,
		PopColumn,
		swiper,
		swiperSlide,
	},
	data() {
		return {
			localLanguage: 1,
			activeIndex: 0,
			boxWidth: 0,
			articleList: [],
			flag: false,
			options: {
				loop: false,
				autoplay: false,
				autoplayDisableOnInteraction: false,
				direction: "horizontal",
				grabCursor: true,
				setWrapperSize: true,
				autoHeight: true,
				initialSlide: 0,
				onSlideChangeEnd: (swiper) => {
					// eslint-disable-line no-shadow
					// 使用es6的箭头函数，使this指向vue对象
					// console.log("--------", swiper.activeIndex);
					this.changePart(swiper.activeIndex);
				},
			},
		};
	},
	created() {
		this.getArticleList({
			category_id: 0,
			type: 1,
			page: this.page,
			size: this.size,
		});
	},
	mounted() {
		this.boxWidth = this.$refs.navBox.clientWidth / 2;

		eventBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "简体中文") {
				this.localLanguage = 0;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 1;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 1;
		}
	},
	methods: {
		changePart(index, e) {
			this.activeIndex = index;
			this.changeItem(index);
			this.flag = true;
			// const offsetLeft = this.$refs.navItem[index].offsetLeft;
			// const offsetWidth = this.$refs.navItem[index].offsetWidth / 2;
			// this.$refs.navBox.scrollLeft = offsetLeft + offsetWidth - this.boxWidth;
			if (e) {
				this.$refs.mySwiper.swiper.slideTo(index);
			}
		},
		changeItem(index) {
			this.articleList = [];
			this.activeIndex = index;
			const offsetLeft = this.$refs.navItem[index].offsetLeft;
			const offsetWidth = this.$refs.navItem[index].offsetWidth / 2;
			this.$refs.navBox.scrollLeft = offsetLeft + offsetWidth - this.boxWidth;
			this.getArticleList({
				category_id: this.categoryLists[index].id,
				type: 1,
				page: this.page,
				size: this.size,
			});
		},
		getArticleList(obj, type) {
			getArticleList(obj)
				.then((res) => {
					if (res.code == 200) {
						this.articleList = res.data.list;
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
.navBox {
	display: flex;
	overflow: scroll;
	scroll-behavior: smooth;
	scroll-duration: 3s;
}
.navBox::-webkit-scrollbar {
	width: 0;
	display: none;
}
.navItem {
	height: 50px;
	box-sizing: border-box;
	padding: 14px 10px;
	flex-shrink: 0;
	line-height: 22px;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #a0a0a9;
}
.activeItem {
	padding: 11px 6px 4px;
	line-height: 28px;
	font-size: 20px;
	color: #000;
}
.activeItem img {
	display: block;
	height: 7px;
	margin: 0 auto;
}
</style>
<style>
.swiper-wrapper {
	height: auto !important;
}
.swiper-slide {
	display: block;
	padding: 0;
}
</style>
