<template>
	<div class="popColumns">
		<div class="topPart">
			<div class="leftTitle">热门专栏</div>
			<div class="rightMoreBtn" @click="more">查看更多</div>
		</div>
		<div
			class="perColumns"
			v-for="(item, index) in popColumnLists"
			:key="index"
		>
			<div
				class="leftInfo"
				@click="
					$router.push({
						path: '/home_wz',
						query: { id: item.userInfo.id },
					})
				"
			>
				<img
					class="leftInfoImage"
					:src="item.userInfo.avatar_img"
					:key="item.userInfo.avatar_img"
				/>
				<div class="infoContent">
					<p class="name">{{ item.userInfo.nickname }}</p>
					<p class="info">{{ item.userInfo.desc }}</p>
				</div>
			</div>
			<!-- <div class="btn actionBtn" v-if="isFocus">关注</div>
			<div class="btn actionedBtn" v-else>已关注</div> -->
		</div>
	</div>
</template>

<script>
import { eventBus } from "../../../components/pc/leftTab";
export default {
	props: ["popColumnLists"],
	data() {
		return {
			isFocus: false,
			localLanguage: 1,
		};
	},
	mounted() {
		eventBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "简体中文") {
				this.localLanguage = 0;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 1;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 1;
		}
	},
	methods: {
		more() {
			this.$router.push("/home_zl");
		},
		action(index) {
			this.isFocus = !this.isFocus;
			this.$emit("action", index);
		},
	},
};
</script>

<style scoped>
.popColumns {
	background: #fafafa;
	padding: 16px 12px;
}
.topPart {
	display: flex;
	justify-content: space-between;
}
.leftTitle {
	line-height: 22px;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000;
}
.rightMoreBtn {
	width: 65px;
	height: 16px;
	line-height: 16px;
	text-align: center;
	background: linear-gradient(140deg, #bf4cfc 0%, #3880fa 100%);
	border-radius: 8px;
	font-size: 11px;
	color: #fff;
}
.perColumns {
	display: flex;
	margin-top: 16px;
	justify-content: space-between;
	align-items: center;
}
.leftInfo {
	display: flex;
}
.leftInfo img {
	display: block;
	margin-right: 8px;
	width: 42px;
	width: 42px;
	border-radius: 21px;
}
.name {
	line-height: 20px;
	font-size: 14px;
	color: #000;
}
.info {
	margin-top: 5px;
	line-height: 14px;
	font-size: 10px;
	color: #a0a0a8;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}
.btn {
	width: 120px;
	height: 23px;
	line-height: 23px;
	border-radius: 12px;
	text-align: center;
	font-size: 12px;
}
.actionBtn {
	border: 1px solid #335ff9;
	color: #335ff9;
}
.actionedBtn {
	border: 1px solid #c7c7cc;
	color: #c7c7cc;
}
</style>
