<template>
	<div class="allNews">
		<!-- <div>{{item.name}}</div> -->
		<div
			class="perNews"
			@click="
				$router.push({
					path: '/gw_detail',
					query: { id: item.id },
				})
			"
		>
			<div class="textPart">
				<div class="mainContent">
					{{
						localLanguage == 2
							? item.title
								? item.title
								: item.title
							: item.title
					}}
				</div>
				<div class="tagBox">
					<p class="intoText tagText">{{ item.author.nickname }} </p>
					<div class="intoText tagText" v-time="item.release_time"></div>
				</div>
			</div>
			<div class="imgPart">
				<img :src="item.pic" :key="item.pic" />
			</div>
		</div>
	</div>
</template>

<script>
import { eventBus } from "../../../components/pc/leftTab";
export default {
	props: ["item"],
	data() {
		return {
			localLanguage: 1,
		};
	},
	mounted() {
		eventBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "简体中文") {
				this.localLanguage = 0;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 1;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 1;
		}
	},
};
</script>

<style scoped>
.allNews {
	padding: 0 12px;
}
.perNews {
	display: flex;
	padding: 12px 0;
	justify-content: space-between;
	border-bottom: 1px solid #f7f7f7;
}
.textPart {
	width: calc(100vw * 270 / 375);
}
.mainContent {
	font-size: 17px;
	line-height: 24px;
	color: #000;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.tagBox {
	display: flex;
	margin-top: 4px;
}
.tagText {
	font-size: 10px;
	line-height: 14px;
	margin-right: 7px;
}
.toTopTag {
	color: #294afd;
}
.flashTagImg {
	display: block;
	width: 12px;
}
.flashTagText {
	color: #9329fd;
}
.intoText {
	color: #a0a0a8;
}
.imgPart {
	width: 100px;
	height: 62px;
	border-radius: 5px;
	overflow: hidden;
}
.imgPart img {
	display: block;
	width: 100px;
	height: 62px;
	object-fit: cover;
}
</style>
